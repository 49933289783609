module.exports = function getLicenseFileName(license) {
  return `license_${versionPrefix(license)}_${license.computerName.trim()}.lic`

  function versionPrefix({ licenseType }) {
    switch (licenseType) {
      case 'v8_orange':
        return '8.0'
      case 'v8_external':
        return '8.0'
      case 'v9_orange':
        return '9.0'
      case 'v9_external':
        return '9.0'
      case 'v10_orange':
        return '10.0'
      case 'v10_external':
        return '10.0'
      default: {
        return '10.0'
      }
    }
  }
}

import React from "react"
import { connect } from "react-redux"
import { Control, Form as ReduxForm, actions } from "react-redux-form"
import { Link } from "gatsby"
import { Form, Col, Button } from "react-bootstrap"

import { expectAuthenticationState } from "../../../components/authentication"
import Piwik from "../../../config/piwik"
import ENVIRONMENT from "../../../config/environment"
import Loading from "../../../components/loading"
import {
  ErrorList,
  validateMachineId,
  validateNoSpacesInComputerName,
} from "../../../components/validation"
import { downloadFile } from "../../../utils/download"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ErrorApi from "../../../components/error"
import SuccessApi from "../../../components/success"
import getLicenseFileName from "../../../../../backend/src/licenses/license-file-name"

const RequestLicensePage = () => (
  <Layout>
    <SEO title="Request a license" />
    <h1 data-test="page-title">Request a license</h1>
    <p>
      Please read the{" "}
      <Link to="/installation-notes-v10">installation notes</Link> in order to
      find your {'"Computer name" and "Machine ID".'}
    </p>
    <p>
      If you have several licenses to request, you can use the{" "}
      <Link to="/my-licenses/request/multiple" data-test="request-multiple">
        multiple licenses request form
      </Link>
      .
    </p>
    <ConnectedRequestMultipleLicenseForm />
  </Layout>
)
export default expectAuthenticationState({ connected: true, onboarded: true })(
  RequestLicensePage,
)

class RequestMultipleLicenseForm extends React.Component {
  usageCount

  constructor() {
    super()
    this.usageCount = 0
    this.state = {
      error: false,
      errorMessage: null,
      requestInProgress: false,
      successful: false,
    }
  }

  render() {
    const { error, errorMessage, requestInProgress, successful } = this.state
    return (
      <>
        {this.props.email.endsWith("orange.com") && (
          <p>
            If you are an internal collaborator, you can also make a{" "}
            <a href={window.location.origin + "/my-tokens"}>
              request by API with tokens
            </a>
            .
          </p>
        )}
        {error && <ErrorApi>{errorMessage}</ErrorApi>}

        {successful && (
          <SuccessApi>
            We have sent you the license details at {this.props.email}.<p />
            <Link to="/my-licenses">Click here to view your licenses</Link>
          </SuccessApi>
        )}
        <ReduxForm
          model="requestLicenseForm.data"
          onSubmit={values => this.handleSubmit(values)}
          onChange={values => this.handleChange(values)}
        >
          <Form.Row>
            <Col md={10} lg={8} xl={6}>
              <Form.Group>
                <Form.Label htmlFor="computerNameField" className="is-required">
                  Computer name
                </Form.Label>
                <Control.text
                  className="form-control"
                  model=".computerName"
                  id="computerNameField"
                  placeholder="Enter computer name (eg: EXAMPLE-PC)"
                  validators={{
                    required: val => val && val.length,
                    noSpaces: val => validateNoSpacesInComputerName(val || ""),
                  }}
                  data-test="computer-name"
                />
                <ErrorList
                  model=".computerName"
                  messages={{
                    required: "Computer name is required",
                    noSpaces: "The Computer name must not include spaces",
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="computerIdField" className="is-required">
                  Machine ID
                </Form.Label>
                <Control.text
                  className="form-control"
                  model=".computerId"
                  id="computerIdField"
                  placeholder="Enter machine ID (eg: EX-AM-PL-E0-ID-EN-TI-FI-CA-TI-ON-00-KE-Y0-00)"
                  validators={{
                    required: val => val && val.length,
                    valid: val => validateMachineId(val || ""),
                  }}
                  data-test="computer-id"
                />
                <ErrorList
                  model=".computerId"
                  messages={{
                    required: "machine ID is required",
                    valid: (
                      <>
                        Enter a valid Machine ID.{" "}
                        <Link to="/installation-notes-v10">
                          Click here to find the detailed procedure.
                        </Link>{" "}
                      </>
                    ),
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label
                  htmlFor="khiopsVersionSelect"
                  className="is-required"
                >
                  Khiops version
                </Form.Label>
                <Control.select
                  type="field"
                  className="custom-select mr-sm-2"
                  model=".khiopsVersion"
                  id="khiopsVersionSelect"
                  data-test="khiops-version"
                >
                  <option key="10" value="10">
                    Khiops V10
                  </option>
                  <option key="9" value="9">
                    Khiops V9
                  </option>
                  <option key="8" value="8">
                    Khiops V8
                  </option>
                </Control.select>
              </Form.Group>

              {!requestInProgress ? (
                <>
                  <Button
                    type="button"
                    variant="secondary"
                    className="mr-2"
                    as={Link}
                    to="/my-licenses"
                    data-test="cancel"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" data-test="submit">
                    Request your license
                  </Button>
                </>
              ) : (
                <Loading />
              )}
            </Col>
          </Form.Row>
        </ReduxForm>
      </>
    )
  }

  handleChange() {
    this.setState({ successful: false })
    this.usageCount++
    Piwik.push(["trackEvent", "RequestLicense", "RequestLicense_FillingForm"])
    if (this.usageCount === 10) {
      Piwik.push([
        "trackEvent",
        "RequestLicense",
        "RequestLicense_FillingForm_moreThan10Chars",
      ])
    }
  }

  async handleSubmit(values) {
    if (ENVIRONMENT.name === "ssr") {
      return // no submit in SSR
    }

    Piwik.push(["trackEvent", "RequestLicense", "RequestLicense_Submit"])

    this.setState({
      requestInProgress: true,
      successful: false,
    })
    const request = {
      computerName: values.computerName,
      computerId: values.computerId,
      khiopsVersion: values.khiopsVersion,
    }
    const response = await fetch("/api/my-licenses/request", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify(request),
    })
    if (response.ok) {
      this.setState(
        { error: false, requestInProgress: false, errorMessage: null },
        async () => {
          const result = await response.json()
          if (result.status === "ok") {
            this.setState({ error: false, successful: true })
            if (!window.Cypress) {
              const { license } = result
              const blob = new window.Blob([license.licenseFile], {
                type: "application/octet-stream",
              })
              downloadFile(getLicenseFileName(license), blob)
            }
          } else {
            this.setState({
              error: true,
              errorMessage:
                "The provided Machine ID does not match the provided Computer name",
            })
          }
        },
      )
    } else {
      this.setState({ error: true, requestInProgress: false })
    }
  }

  componentWillUnmount() {
    this.props.resetModel("requestLicenseForm.data")
  }
}

function mapStateToProps(state) {
  return {
    email: state.user.email,
  }
}

const ConnectedRequestMultipleLicenseForm = connect(
  mapStateToProps,
  dispatch => ({
    resetModel: model => dispatch(actions.reset(model)),
  }),
)(RequestMultipleLicenseForm)

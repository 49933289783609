export function downloadFile(filename, blob) {
  setTimeout(() => {
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      const element = document.createElement("a")
      element.setAttribute("href", window.URL.createObjectURL(blob))
      element.setAttribute("download", filename)
      element.setAttribute(
        "downloadurl",
        ["text/plain", element.download, element.href].join(":"),
      )
      element.style.display = "none"

      const { body } = document
      if (body) {
        body.appendChild(element)
        element.click()
        body.removeChild(element)
      }
    }
  })
}
